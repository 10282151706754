
export const trimZerosAtTheEnd = value => {
    const result = /.*[^0]/g.exec(value);
    if(!result) {
        return '';
    }
    return result[0];
}
export const getCountOfDigitsAfterComma = value => trimZerosAtTheEnd(value.toString().split('.')[1]).length;

export const add = (a, b) => {
    const d1 = getCountOfDigitsAfterComma(a);
    const d2 = getCountOfDigitsAfterComma(b);
    const d = Math.max(d1, d2);
    return Number((a + b).toFixed(d));
};
export const minus = (a, b) => a - b;
export const multiply = (a, b) => {
    const d1 = getCountOfDigitsAfterComma(a);
    const d2 = getCountOfDigitsAfterComma(b);
    const d = d1 + d2;
    return Number((a * b).toFixed(d));
};
export const divide = (a, b) => a / b;

export const operatorsMap = {
    add,
    '+': add,
    minus,
    '-': minus,
    multiply,
    '*': multiply,
    divide,
    '/': divide,
}
